<mat-toolbar class="p-0" color="primary">
  <div class="d-none d-sm-block">
    <mat-toolbar-row class="menu-principal">
      <div class="container">
        <div class="toolbar-content">
          <img src="assets/logos/logo_original.png" style="max-height: 45px; max-width: 45px" class="logo" alt="Logo"
            [routerLink]="['/']" />
          <a *ngFor="let link of links" mat-button [href]="link.route" class="toolbar-link" target="_blank">
            {{ link.text }}
          </a>
          <div class="spacer"></div>
          <div>
            <!-- <button class="btn login">Entrar</button>
            <button class="btn rounded-pill px-3 register">Cadastre-se</button> -->
          </div>
          <div>
            <!--<a href="https://bolivia.remateagroshop.com/" class="btn rounded-pill p-0 ms-2 me-2"><img style="width: 32px; height: auto;" src="/assets/Flag_orb_Bolivia.png" alt="" /></a>-->
            <a href="https://remateagroshop.com/" class="btn rounded-pill p-0 ms-2 me-2"><img style="width: 32px; height: auto;" src="/assets/Flag_orb_Brazil.png" alt="" /></a>
          </div>
        </div>
      </div>
    </mat-toolbar-row>
  </div>

  <div class="d-block d-sm-none">
    <mat-toolbar-row class="menu-principal">
      <div class="container">
        <div class="toolbar-content">
          <div class="toolbar-content">
            <div class="row w-100">
              <div class="col-4">
                <img [matMenuTriggerFor]="appMenu" src="/assets/menu.svg" />
              </div>
              <div class="col-4 text-center">
                <img src="assets/logos/logo_original.png" style="max-height: 45px; max-width: 45px" class="logo" alt="Logo"
                [routerLink]="['/']" />
              </div>
              <div class="col-4 mobile-country-select">
                <!--<a href="https://bolivia.remateagroshop.com/" class="btn rounded-pill p-0 me-2"><img style="width: 28px; height: auto;" src="/assets/Flag_orb_Bolivia.png" alt="" /></a>-->
                <a href="https://remateagroshop.com/" class="btn rounded-pill p-0 ms-2"><img style="width: 28px; height: auto;" src="/assets/Flag_orb_Brazil.png" alt="" /></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </mat-toolbar-row>

    <mat-menu #appMenu="matMenu" class="d-block d-sm-none">
      <ng-template matMenuContent>
        <div class="menu-principal">
          <a *ngFor="let link of links" [href]="link.route" target="_blank" mat-menu-item>
            {{ link.text }}
          </a>

        </div>
      </ng-template>
    </mat-menu>
  </div>

  <mat-toolbar-row class="barra-remate bg-#006086">
    <div id="sub-header" class="w-100 p-0">
      <div class="container">
        <div class="d-flex justify-content-between flex-wrap">
          <span class="sub-header-item align-centrer mt-2 mb-1">Remate Agroshop</span>

          <a class="btn btn-secondary btn-sell" target="_blank" href="https://api.whatsapp.com/send?phone=5543991813031&text=Quero%20vender%20meu%20produto%20na%20Remate%20AGROSHOP!">{{ translations.sell_with_us }}</a>
          <!-- <span class="sub-header-item align-centrer">VENDA CONOSCO</span> -->
          <!-- <span class="sub-header-item">
            <img src="/assets/white-search.svg" class="me-1" />
            O que deseja comprar?
          </span> -->
        </div>
      </div>
      <!-- <div class="section-divisor"></div> -->
    </div>
  </mat-toolbar-row>
</mat-toolbar>
